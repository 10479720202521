<!--
 * @Author: dingguowei
 * @Date: 2023-06-06 15:16:33
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-06-21 14:55:05
-->
<!-- 审核任务详情页面 -->
<template>
  <div class="taskdetails">
    <div class="left">
      <taskheadInfo :task-info="taskData"></taskheadInfo>

      <el-card class="tabs">
        <el-tabs v-model="activeName">
          <el-tab-pane label="文章详情" name="文章详情">
            <div class="body" style="font-size: 0.8rem" v-html="taskData.description"></div>
          </el-tab-pane>
          <el-tab-pane label="任务详情" name="任务详情">
            <task_tabs_details :task-info="taskData.salaryListVos" :money-data="taskData"></task_tabs_details>
          </el-tab-pane>
          <el-tab-pane label="任务进度" name="任务进度">
            <task_tabs_process :task-info="taskData"></task_tabs_process>
          </el-tab-pane>
          <el-tab-pane label="任务完成情况" name="任务完成情况">
            <task_tabs_result :mission-receive-id="missionReceiveId" :task-info="taskData"
              :salary-list-vos="taskData.salaryListVos" :img-url="taskData"></task_tabs_result>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
    <div class="right">
      <el-card>
        <div style="text-align: center" v-if="status === 1">
          <p style="font-size: 0.8rem; font-weight: 600">任务审批</p>
          <el-button plain size="mini" style="width: 60%; margin-top: 10px"
            :style="{ color: statusColor(0), borderColor: statusColor(0) }" @click="refusetask">不通过</el-button>
          <el-button plain size="mini" style="width: 60%; margin-top: 10px"
            :style="{ color: statusColor(1), backgroundColor: statusColor(0) }" @click="passtask">审核通过</el-button>
          <el-button plain size="mini" style="width: 60%; margin-top: 10px"
            :style="{ color: statusColor(1), backgroundColor: statusColor(2) }" @click="blocktask">拉黑</el-button>
        </div>
        <div style="text-align: center" v-else>
          <p style="font-size: 0.8rem; font-weight: 600">审批结果</p>
          <el-result v-if="status === 3" icon="success" subTitle="任务通过">
          </el-result>
          <el-result v-else-if="status === 4" icon="warning" subTitle="已拒绝">
          </el-result>
          <el-result v-else-if="status === 5" icon="error" subTitle="已拉黑">
          </el-result>
        </div>
      </el-card>
    </div>
  </div>
</template>
  
<script>
import { gettaskInfo } from "@/api/home";
import { blocktask, refusetask, passtask } from "@/api/audit";
import { mapState } from "vuex";
import store from "@/store";
// 组件
import taskheadInfo from "@/components/task/taskheadInfo.vue";
import task_tabs_details from "@/components/task/task_tabs_details.vue";
import task_tabs_process from "@/components/task/task_tabs_process.vue";
import task_tabs_result from "@/components/task/task_tabs_result.vue";
export default {
  components: {
    taskheadInfo,
    task_tabs_details,
    task_tabs_process,
    task_tabs_result,
  },
  data() {
    return {
      taskData: {},
      activeName: "文章详情",
      // missionReceiveId: null,
      status: null,
    };
  },
  created() {
    this.status = this.$route.params.status;
  },
  mounted() {
    this.gettaskInfo();
  },
  watch: {},
  computed: {
    ...mapState("task", ["taskInfo"]),
    statusColor() {
      return function (val) {
        if (val === 0) {
          return "#587AEF";
        } else if (val === 1) {
          return "#ffffff";
        } else if (val === 2) {
          return "#FE855E";
        } else {
          return "#909399";
        }
      };
    },
    missionReceiveId() {
      return store.state.task.taskInfo.missionReceiveId;
    },
  },
  methods: {
    // 获取点进来的发布任务详情
    gettaskInfo() {
      gettaskInfo({
        missionId: this.taskInfo.missionId,
      }).then((res) => {
        this.taskData = res.data;
        console.log(
          "🚀 ~ file: auditDetails.vue:142 ~ gettaskInfo ~  this.taskData:",
          this.taskData
        );
      });
    },
    // 各种审核操作
    // 不通过操作
    async refusetask() {
      await refusetask({
        missionReceiveId: this.missionReceiveId,
      }).then((res) => {
        this.gettaskInfo();
        this.status = 4;
      });
    },
    // 通过操作
    async passtask() {
      await passtask({
        missionReceiveId: this.missionReceiveId,
      }).then((res) => {
        this.gettaskInfo();
        this.status = 3;
      });
    },
    // 拉黑操作
    async blocktask() {
      await blocktask({
        missionReceiveId: this.missionReceiveId,
      }).then((res) => {
        this.gettaskInfo();
        this.status = 5;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.taskdetails {
  box-sizing: border-box;
  padding: 1rem 2rem 1rem 2rem;
  display: flex;
  user-select: text;

  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
    border-radius: 999px;
    border: 5px solid transparent;
  }

  ::-webkit-scrollbar-track {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2) inset;
  }

  ::-webkit-scrollbar-thumb {
    min-height: 20px;
    background-clip: content-box;
    box-shadow: 0 0 0 5px rgba(23, 58, 230, 0.653) inset;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  .left {
    flex: 1;
    display: flex;
    flex-direction: column;

    .tabs {
      flex: 1;

      ::v-deep .el-tabs {
        height: 100% !important;
        display: flex;
        flex-direction: column;
        // overflow: auto;
      }

      ::v-deep .el-tabs__content {
        flex: 1;
        width: 100%;
        overflow: auto;
      }

      ::v-deep .el-tabs__item {
        font-size: 0.8rem;
      }

      ::v-deep .el-card__body {
        box-sizing: border-box;
        height: 100%;
      }

      .body {}
    }
  }

  .right {
    width: 20rem;
    margin-left: 10px;

    ::v-deep .el-button {
      margin-left: 0;
      border: 1px solid;
    }

    .clearfix {
      font-weight: 600;
      font-size: 0.9rem;
      text-align: center;
    }
  }
}
</style>