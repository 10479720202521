<template>
  <div
    v-loading="loading"
    element-loading-text="数据加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.2)"
    class="h-100 p-r"
    style="margin-top: 20px"
  >
    <div class="timelineBox">
      <el-timeline>
        <el-timeline-item
          v-for="(item, index) in versionAnnouncementList"
          :key="index"
          color="#6080EF"
        >
          <div v-if="index % 2 == 0" class="evenDiv">
            <div class="timelinItemBox0 dialogBox">
              <div class="timelinItemBox-bottom">
                <span>
                  <p style="color: #999999">{{ item.date }}</p>
                  {{ item.content }}
                </span>
              </div>
            </div>
            <div class="timelinItemBox1 dialogBox">
              <div class="timelinItemBox-bottom">
                <p>{{ item.date }}</p>
                {{ item.content }}
              </div>
            </div>
          </div>
          <div v-else class="unevenDiv">
            <div class="timelinItemBox0 dialogBox">
              <div class="timelinItemBox-bottom">
                <p>{{ item.date }}</p>
                {{ item.content }}
              </div>
            </div>
            <div class="timelinItemBox1 dialogBox">
              <div class="timelinItemBox-bottom">
                <p style="color: #999999">{{ item.date }}</p>
                {{ item.content }}
              </div>
            </div>
          </div>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>
  
<script>
import { gettimeline } from "@/api/audit";
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      versionAnnouncementList: [
        {
          uuid: "20230314_1053_5398371131177979",
          content: "1",
          version: "V3.1",
          color: "#000000",
        },
        {
          uuid: "20230314_1053_5398371131177979",
          content: "1",
          version: "V3.1",
          color: "#21A0AD",
        },
      ],
      loading: false,
    };
  },
  mounted() {
    this.gettimeline();
  },
  computed: {
    ...mapState("task", ["taskInfo"]),
  },
  methods: {
    // 获取时间线
    async gettimeline() {
      await gettimeline({
        missionId: this.taskInfo.missionId,
        pageNo: 1,
        pageSize: 100,
        userId: this.taskInfo.receiverId,
      }).then((res) => {
        this.handleData(res.data.records);
      });
    },
    handleData(val) {
      function desc(status) {
        if (status === "TOFINISH") {
          return "用户领取任务";
        } else if (status === "TOJUDGE") {
          return "用户提交任务";
        } else if (status === "FINISHED") {
          return "审核通过任务";
        } else if (status === "REFUSED") {
          return "审核拒绝任务";
        } else if (status === "FORBID") {
          return "审核拉黑该用户";
        } else if (status === "CLOSED") {
          return "用户关闭任务";
        }
      }
      let data = new Array();
      val.map((res) => {
        // 处理数据
        console.log({
          content: desc(res.status),
          date: res.createdOn,
        });
        data.push({
          content: desc(res.status),
          date: res.createdOn,
        });
      });
      this.versionAnnouncementList = data;

      // 修改versionAnnouncementList
    },
  },
};
</script>
  
<style lang='scss'>
// 通用样式 start

.h-100 {
  height: 100%;
}

.p-r {
  position: relative;
}

.f-r {
  display: flex;
  flex-direction: row;
}

.a-c {
  align-content: center;
  align-items: center;
}

.m-l20 {
  margin-left: 20px;
}

// 通用样式 end

.timelineBox {
  position: absolute;
  left: 50%;
  top: 2%;
  width: 40%;

  .el-timeline-item {
    padding-bottom: 5px;
  }

  .el-timeline-item__tail {
    border-left: 2px solid #a9aaad !important;
  }
}

.timelinItemBox0 {
  position: relative;
  // box-shadow: 1px 1px 5px rgba(120, 119, 119, 0.2);
  width: calc(100% - 28px - 2px);
  height: calc(100% - 2px);
  position: absolute;
  right: calc(100% + 18px);
  border: 1px solid E6E6E6;
  border-radius: 5px;
}

.timelinItemBox1 {
  position: relative;
  // box-shadow: 1px 1px 5px rgba(120, 119, 119, 0.2);
  height: calc(100% - 2px);
  border: 1px solid E6E6E6;
  border-radius: 5px;
}

.timelinItemBox-top {
  height: 35px;
  padding: 0 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #ffffff;
}

.timelinItemBox-bottom {
  padding: 10px;
  color: #606266;
  line-height: 20px;
  white-space: pre-wrap;
  font-size: 0.9rem;

  & p {
    font-size: 0.7rem;
  }
}

.evenDiv {
  text-align: right;

  .timelinItemBox1 {
    box-shadow: none;
    border: 1px solid #ffffff;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: text;

    .timelinItemBox-bottom {
      color: #ffffff;
    }
  }

  .timelinItemBox0:before {
    content: "";
    position: absolute;
    top: 10px;
    left: calc(100%);
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent #e6e6e6;
  }

  .timelinItemBox0:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
  }
}

.unevenDiv {
  .timelinItemBox0 {
    box-shadow: none;
    border: 1px solid #ffffff;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: text;

    .timelinItemBox-bottom {
      color: #ffffff;
    }
  }

  .timelinItemBox1:before {
    content: "";
    position: absolute;
    top: 10px;
    right: 100%;
    border-width: 10px;
    border-style: solid;
    border-color: transparent #dedede transparent transparent;
  }

  .timelinItemBox1:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #dedede;
    border-radius: 5px;
  }
}
</style>
  
<!-- <template>
    <div id="app">
        <el-timeline>
            <el-timeline-item v-for="(activity, index) in activities1" :key="index" :icon="activity.icon"
                :type="activity.type" :size="activity.size">

               
                <div class="companydel">
                    <el-card>
                        <h4>更新 Github 模板</h4>
                        <p>王小虎 提交于 2018/4/12 20:46</p>
                    </el-card>
                </div>
           
                <div>
                    <el-card>
                        <h4>更新 Github 模板</h4>
                        <p>王小虎 提交于 2018/4/12 20:46</p>
                    </el-card>
                </div>

            </el-timeline-item>


        </el-timeline>




    </div>
</template>

<script> -->
<!-- // export default {
//     components: {},
//     data() {
//         return {
//             drawer: false,
//             activities1: [{
//                 title: '装箱单',
//                 content1: '收货人：我爱我的家弟弟吧爸爸妈妈 我爱我的家弟弟爸爸妈妈',
//                 content2: '翻译:',
//                 company: '我爱我的家弟弟吧爸爸妈妈',
//                 comtype: '核心企业',
//                 timestamp: '2018-04-12 20:46',
//                 size: 'large',
//                 type: 'primary',
//                 icon: 'el-icon-more'

//             },

//             {

//                 title: '分提运单',
//                 content1: '收货人：我爱我的家弟弟吧爸爸妈妈 我爱我的家弟弟爸爸妈妈',
//                 content2: '翻译:',
//                 company: '我爱我的家弟弟吧爸爸妈妈',
//                 comtype: '核心企业',
//                 timestamp: '2018-04-12 20:46',
//                 size: 'large',
//                 type: 'primary',
//                 icon: 'el-icon-more'
//             },

//             {

//                 title: '发票',
//                 content1: '收货人：我爱我的家弟弟吧爸爸妈妈 我爱我的家弟弟爸爸妈妈',
//                 content2: '翻译:',
//                 company: '我爱我的家弟弟吧爸爸妈妈',
//                 comtype: '核心企业',
//                 timestamp: '2018-04-12 20:46',
//                 size: 'large',
//                 type: 'primary',
//                 icon: 'el-icon-more'

//             }

//                 , {

//                 title: '进口采购订单',
//                 content1: '收货人：我爱我的家弟弟吧爸爸妈妈 我爱我的家弟弟爸爸妈妈',
//                 content2: '翻译:',
//                 company: '我爱我的家弟弟吧爸爸妈妈',
//                 comtype: '核心企业',
//                 timestamp: '2018-04-12 20:46',
//                 size: 'large',
//                 type: 'primary',
//                 icon: 'el-icon-more'

//             }]

//         }
//     },
//     created() { },
//     mounted() { },
//     watch: {},
//     computed: {},
//     methods: {},
// }
// </script>
<style lang="scss" scoped>
// #app {
//     position: absolute;
//     top: 50px;
//     left: 50%;
//     width: 25%;
// }

// .companydel {
//     color: #909399;
//     position: absolute;
//     left: -200px;
//     top: 1px;
//     font-size: 12px;

// }

// .fl {
//     float: left;
// }

// .fr {
//     float: right;

// }
// </style> -->